.contact {
  background-color: black;
  padding-bottom: 420px;
  position: relative;
  z-index: 0;

  & p,
  h1,
  button {
    position: relative;
    z-index: 1;
  }

  & button {
    cursor: pointer;
    min-width: fit-content;
    white-space: nowrap;
  }

  & img {
    position: absolute;
    right: 20%;
    top: 200px;
    z-index: 0;

    @media only screen and (min-width: 1880px) and (max-width: 2100px) {
      right: 16.4%;
    }

    @media only screen and (min-width: 1440px) and (max-width: 1800px) {
      right: 6.2%;
    }
  }

  & button {
    margin-top: 100px;
    border: solid 1px white;
    border-radius: 100px;
    color: white;
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    padding: 21px 60px;
    transition-duration: 0.25s;
    &:hover {
      background-color: white;
      color: black;
      transition-duration: 0.25s;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .contact {
    padding-bottom: 224px;

    & img {
      right: 5%;
      top: 192px;
    }
  }

  .contact button {
    font-size: 18px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 720px) {
  .contact {
    text-align: center;
    height: 800px;

    & button {
      margin-top: 71px;
    }

    & img {
      position: relative;
      width: 260px;
      top: 100px;
      margin: auto;
      right: 0;
    }
  }
}

@media only screen and (max-width: 720px) {
  .contact {
    & button {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 475px) {
  .contact {
    & button {
      padding: 25px;
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .contact {
    & button {
      padding: 15px 20px;
      font-size: 12px;
    }
  }
}