body {
  background-color: black;
}

.home {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  min-width: 353px;

  section {
    padding-top: 140px;
    scroll-margin-top: 105px;
  }
}

.header {
  font-size: 100px;
  font-weight: 800;
  line-height: 110px;
}

.section-title {
  font-size: 30px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 20px;
}

.transparent {
  color: transparent;
}

.fade-in {
  animation: fade-in 2.5s ease forwards;
  -webkit-animation: fade-in 2.5s ease forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1440px) {
  .home {
    & section {
      scroll-margin-top: 100px;
    }
  }
}
@media only screen and (max-width: 1300px) {
  .header {
    font-size: 70px;
    line-height: 77px;
  }

  .section-title {
    font-size: 20px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .home {
    & section {
      padding-top: 90px;
    }
    .header {
      font-size: 60px;
      line-height: 66px;
    }

    .section-title {
      font-size: 16px;
      line-height: 16px;
    }
  }
}
@media only screen and (max-width: 720px) {
  .home {
    & section {
      padding-top: 90px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .home {
    & section {
      padding-top: 60px;
    }
    .header {
      font-size: 36px;
      line-height: 39.6px;
    }

    .section-title {
      font-size: 13px;
      line-height: 13px;
    }
  }
}
