.expertise {
  padding-bottom: 217px;
  background-color: white;

  & .categories {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 145px;

    & .cat {
      width: fit-content;
      flex: 1 0 20%;
      white-space: nowrap;
      height: auto;
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 800;
      line-height: 33px;
      background-size: contain;
      background-repeat: no-repeat;
      margin: 15px 1%;
      position: relative;
      max-width: 289px;
      min-height: 54px;

      & img {
        position: absolute;
        z-index: 0;
        width: 100%;
      }

      & p {
        z-index: 1;
      }

      &.dark {
        color: #b2ffc8;
      }
      &.one {
        width: 250px;
      }
      &.two {
        width: 218px;
      }
      &.three {
        width: 262px;
      }
      &.four {
        width: 202px;
      }
      &.five {
        width: 289px;
      }
      &.six {
        width: 270px;
      }
      &.seven {
        width: 289px;
      }
      &.eight {
        width: 250px;
      }
      &.mobile {
        display: none;
      }
    }

    &.small {
      display: none;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .expertise {
    padding-bottom: 150px;
  }
  .expertise .categories {
    margin-top: 84px;

    & .cat {
      flex: 30%;
      max-width: 281px;
      & p {
        font-size: 28px;
        line-height: 28px;
      }
      &.seven {
        margin-right: 140px;
      }
      &.eight {
        margin-left: 140px;
      }
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .expertise .categories {
    & .cat {
      flex: 28%;
      margin: 15px 0;
      & p {
        font-size: 24px;
        line-height: 24px;
      }

      &.one {
        max-width: 242px;
      }
      &.two {
        max-width: 212px;
      }
      &.three {
        max-width: 255px;
      }
      &.four {
        max-width: 196px;
      }
      &.five {
        max-width: 263px;
      }
      &.six {
        max-width: 238px;
      }
      &.seven {
        max-width: 242px;
        margin-right: 100px;
      }
      &.eight {
        max-width: 282px;
        margin-left: 100px;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .expertise .categories {
    & .cat {
      flex: 24%;
      margin: 5px 5px;
      & p {
        font-size: 20px;
        line-height: 20px;
      }

      &.one {
        max-width: 200px;
      }
      &.two {
        max-width: 175px;
      }
      &.three {
        max-width: 210px;
      }
      &.four {
        max-width: 162px;
      }
      &.five {
        max-width: 217px;
      }
      &.six {
        max-width: 197px;
      }
      &.seven {
        // max-width: 173px;
        margin-right: 50px;
        margin-left: 15px;
      }
      &.eight {
        // max-width: 232px;
        margin-left: 50px;
        margin-right: 15px;
      }
    }
  }
}

@media only screen and (max-width: 625px) {
  .expertise {
    padding-bottom: 60px;
    & .categories {
      margin-top: 35px;
      &.large {
        display: none;
      }
      &.small {
        display: flex;
      }
      & .cat {
        flex: 40%;
        margin: 5px;
        & p {
          font-size: 20px;
          line-height: 20px;
        }
        &.one {
          max-width: none;
          flex: 42%;
        }
        &.two {
          max-width: none;
          flex: 38%;
        }
        &.three {
          max-width: none;
          flex: 44%;
        }
        &.four {
          max-width: none;
          flex: 36%;
        }
        &.five {
          max-width: none;
        }
        &.six {
          max-width: none;
        }
        &.seven {
          margin: 5px;
          max-width: none;
        }
        &.eight {
          margin: 5px;
          max-width: none;
        }
      }
    }
  }

  @media only screen and (max-width: 463px) {
    .expertise .categories {
      & .cat {
        flex: 40%;
        margin: 0 5px;
        padding: 5px;
        min-height: 45px;
        & p {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
  }
}
