.container {
    padding: 0 20%;

    @media only screen and (max-width: 2100px) {
        padding: 0 16.4%;
    }

    @media only screen and (max-width: 1830px) {
        padding: 0 12%;
    }

    @media only screen and (max-width: 1600px) {
        padding: 0 7.5%;
    }
    @media only screen and (max-width: 1440px) {
        padding: 0 9.375%;
    }
    @media only screen and (max-width: 960px) {
        padding: 0 8.33%;
    }
    @media only screen and (max-width: 460px) {
        padding: 0 8.33%;
    }
}

