.ingredients {
  padding-top: 200px;
  padding-bottom: 210px;
  background-color: #f4f4f4;
}

.recipe {
  margin-top: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.mobile {
    display: none;
  }
  & .ingredient-box {
    text-align: center;
    width: 30%;
    & br {
      display: none;
    }

    & img {
      width: 220px;
      height: 220px;
    }
    & h3 {
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin-top: 100px;
    }

    & p {
      font-family: "Vollkorn", serif;
      font-size: 26px;
      font-weight: 400;
      line-height: 39px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .recipe .ingredient-box {
    & br {
      display: block;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .ingredients {
    padding-bottom: 130px;
  }
  .recipe {
    margin-top: 100px;
    & .ingredient-box {
      width: 31%;
      & img {
        width: 140px;
        height: 140px;
      }
      & h3 {
        margin-top: 52px;
        font-size: 20px;
        line-height: 24px;
      }
      & p {
        font-size: 18px;
        line-height: 27px;
        margin-top: 20px;
      }
      & br {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .ingredients {
    padding-bottom: 0px;
  }
  .recipe {
    &.desktop {
      display: none;
    }
    &.mobile {
      display: block;
      margin-top: 100px;

      & .serpentine .serpentine-row {
        margin-bottom: 80px;
        & .img-div img {
          height: 180px;
        }

        & .text h3 {
          margin-top: 45px;
          font-size: 30px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .recipe.mobile {
    margin-top: 0;
    .serpentine .serpentine-row {
      margin-bottom: 70px;
      &.last {
        margin-bottom: 0px;
      }

      & .img-div img {
        height: 160px;
      }

      & .text {
        padding: 10px 0;
        & h3 {
          font-size: 24px;
          line-height: 28px;
          margin-top: 10px;
        }
        & p {
          font-size: 16px;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
}
