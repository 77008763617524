.platform {
  background: linear-gradient(
    to bottom,
    black 0%,
    black 72%,
    white 72%,
    white 100%
  );
  height: 750px;
  color: white;
}

.platform-boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 40px;
}

.platform-box {
  background-color: #1c1c1c;
  height: 480px;
  width: 400px;
  position: relative;
  padding-bottom: 60px;
  padding-top: 40px;
  padding-left: 40px;

  & .logo-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-right: 40px;
  }

  & img {
    width: 90px;
    height: 90px;
  }

  & .platform-title {
    font-size: 40px;
    font-weight: 400;
    line-height: 1.1;

    & .accent-text {
      font-weight: 800;
    }
  }

  & .platform-desc {
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    margin-top: 22px;
    opacity: 0.7;
    padding-right: 25px;
    font-family: "Vollkorn", serif;
  }

  & .platform-footer {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    position: absolute;
    top: 390px;
  }
}

.opacity05 {
  opacity: 0.5;
}

@media only screen and (max-width: 1440px) {
  .platform {
    height: 1100px;
    background: linear-gradient(
      to bottom,
      black 0%,
      black 88%,
      white 88%,
      white 100%
    );

    & .platform-boxes {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    & .platform-box {
      width: 100%;
      height: 280px;
      margin-bottom: 20px;
      padding-top: 30px;
    }
    & .logo-wrapper {
      position: absolute;
      top: 0px;
      right: 20px;
      margin-top: 30px;
    }
    & .platform-title {
      display: flex;
      flex-direction: row;
      // margin-top: 30px;

      & .accent-text {
        margin-left: 0.25em;
      }
    }
    & .platform-desc {
      margin-top: 30px;
      width: 70%;
      &.three {
        width: 75%;
      }
    }
    & .platform-footer {
      top: auto;
      bottom: 36px;

      & br {
        display: none;
      }

      &.three {
        display: flex;

        & .opacity05 {
          margin: 0 0.25em;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .platform {
    height: 910px;
    background: linear-gradient(
      to bottom,
      black 0%,
      black 85%,
      white 85%,
      white 100%
    );
    & .platform-boxes {
      padding-top: 15px;
    }
    & .platform-box {
      height: 220px;
      & .logo-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        & img {
          width: 60px;
          height: 60px;
        }
      }
      & .platform-title {
        font-size: 30px;
        line-height: 36px;

        & .accent-text {
          margin-left: 0.25em;
        }
      }
      & .platform-desc {
        width: 70%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 27px;
        &.three {
          width: 75%;
        }
      }
      & .platform-footer {
        bottom: 26px;
        font-size: 16px;
        line-height: 24px;

        &.three {
          display: flex;

          & .opacity05 {
            margin: 0 0.25em;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .platform {
    height: 910px;
    background: linear-gradient(
      to bottom,
      black 0%,
      black 85%,
      white 85%,
      white 100%
    );
    & .platform-boxes {
      padding-top: 15px;
    }
    & .platform-box {
      height: 220px;
      & .logo-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
        & img {
          width: 60px;
          height: 60px;
        }
      }
      & .platform-title {
        font-size: 30px;
        line-height: 36px;

        & .accent-text {
          margin-left: 0.25em;
        }
      }
      & .platform-desc {
        width: 70%;
        margin-top: 20px;
        font-size: 18px;
        line-height: 27px;
        &.one {
          width: 70%;
        }
        &.three {
          width: 70%;
        }
      }
      & .platform-footer {
        bottom: 26px;
        font-size: 16px;
        line-height: 24px;
        padding-right: 2em;

        &.three {
          display: flex;

          & .opacity05 {
            margin: 0 0.25em;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 693px) {
  .platform {
    height: auto;
    background: linear-gradient(
      to bottom,
      black 0%,
      black 88%,
      white 88%,
      white 100%
    );
    & .platform-boxes {
      padding-top: 15px;
    }
    & .platform-box {
      height: 260px;
      & .logo-wrapper {
        position: absolute;
        top: -10px;
        right: -10px;
        & img {
          width: 60px;
          height: 60px;
        }
      }
      & .platform-title {
        font-size: 24px;
        line-height: 28.8px;
        display: block;

        & .accent-text {
          margin-left: 0;
        }
      }
      & .platform-desc {
        width: 100%;
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        &.one {
          width: 100%;
        }
        &.three {
          width: 100%;
        }
      }
      & .platform-footer {
        bottom: 26px;
        font-size: 13px;
        line-height: 19.5px;
        padding-right: 2em;
      }
    }
  }
}

@media only screen and (max-width: 445px) {
  .platform {
    & .platform-boxes {
      padding-top: 8px;
    }
    & .container {
      padding: 0 5.5%;
    }
    & .platform-box {
      padding-left: 30px;
      & .logo-wrapper {
        top: -16px;
        right: -10px;
        & img {
          width: 60px;
          height: 60px;
        }
      }
      & .platform-footer {
        & br {
          display: block;
        }
        &.three {
          display: block;

          & .opacity05 {
            margin: 0 0.25em;
          }
        }
      }
      &.last {
        margin-bottom: 0;
      }
    }
  }
}
