.hero {
  height: auto;
  margin-top: 110px;
  background-color: black;
  color: white;
  padding-top: 210px;
}

.hero-desc {
  font-family: "Vollkorn", serif;
  font-size: 36px;
  line-height: 54px;
  font-weight: 400;
  color: #ffffffb2;
  margin: 100px 0 168px;

  & .first-line {
    color: #ffffff;
    font-weight: 700;
  }
}

@media only screen and (max-width: 1300px) {
  .hero {
    & .container {
      padding: 0 1% 0 9.375% !important;
    }
    .hero-desc {
      font-size: 28px;
      line-height: 42px;
      margin: 90px 0 80px;
    }
    .hero .container {
      padding: 0 7.6% 0 9.375% !important;
    }
  }
}

@media only screen and (max-width: 960px) {
  .hero {
    margin-top: 110px;
    & .container {
      padding: 0 7.6% 0 9.375% !important;
    }
    .hero-desc {
      font-size: 20px;
      line-height: 30px;
      margin: 90px 0 65px;
    }
  }
}

@media only screen and (max-width: 762px) {
  .hero .break {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .hero {
    & h1 {
      padding-top: 0px;
    }
    & .hero-desc {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .hero {
    margin-top: 40px;
    & h1 {
      padding-top: 0px;
    }
    & .hero-desc {
      font-size: 16px;
      line-height: 24px;
      margin: 30px 0;
    }
  }
}