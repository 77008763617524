.navbar {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 0 40px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  width: 100%;
  z-index: 10;
  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .nav {
    padding: 20px;
    font-size: 18px;
    cursor: pointer;
    &:hover {
      color: #64c67f;
    }
    &.email {
      color: #64c67f;
      &:hover {
        color: #c8ffd8;
      }
    }
  }

  .nav-logo {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    &:hover {
      svg path {
        fill: #64c67f;
      }
    }
  }

  & #menu {
    transition: 0.15s ease-out;
  }

  & .mobile-menu {
    position: fixed;
    top: 110px;
    right: 0;
    background-color: black;
    width: auto;
    text-align: end;
    padding-right: 40px;
  }
}

.mobile-menu.hide {
  right: -260px;
  opacity: 0;
  transform: translateX(0%);
}

@media only screen and (max-width: 960px) {
  .mobile-menu {
    display: block;
    padding-left: 40px;
    &.hide {
      display: block;
      right: -300px;
    }
  }
  .hamburger {
    display: block;
    padding: 20px;
    animation: slide-in 0.15s ease forwards;
    -webkit-animation: slide-in 0.15s ease forwards;
    cursor: pointer;
    transition: transform 0.7s ease-out;

    & svg {
      transform: rotate(0deg);
      transition: transform 0.3s ease;
    }
    &:hover {
      svg {
        transform: rotate(180deg);
        transition: transform 0.3s ease;

        & line {
          stroke: #64c67f;
        }
      }
    }
  }

  .right p, .right a {
    display: none;
  }
}

@media only screen and (min-width: 960.1px) {
  .hamburger {
    display: none;
  }

  .mobile-menu {
    right: -260px !important;
  }
}

@media only screen and (max-width: 720px) {
  .navbar {
    padding: 0 20px;
  }

  .mobile-menu {
    padding-left: 20px;
    & .nav {
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 400px) {
  .navbar {
    height: 60px;
    padding: 0;

    & .nav-logo {
      padding: 15px 20px;
      & svg {
        width: 30px;
        height: 30px;
      }
    }

    & .hamburger {
      padding: 17px 20px;
      & svg {
        width: 24px;
        height: 10px;
      }
    }
    
    & .mobile-menu {
      top: 60px;
      padding-right: 20px;
      padding-left: 0;
      & p {
        font-size: 14px;
      }

      &.hide {
        right: -200px;
      }
    }
  }
}
