.formula {
  padding-bottom: 100px;
  background-color: white;
}

.gray {
  color: #000000;
  opacity: 0.5;
}

.black {
  color: #000000;
}

.serpentine {
  margin: 100px 60px 0;
  display: flex;
  flex-direction: column;

  & .serpentine-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 200px;

    & .img-div {
      // width: 50%;
      & img {
        height: 320px;
      }

      &.mobile {
        display: none;
      }

      &.right {
        text-align: right;
      }
    }

    & .text {
      width: 50%;
      padding-left: 20px;

      & h3 {
        font-size: 60px;
        font-weight: 800;
        line-height: 66px;
        margin-top: 60px;
      }

      & p {
        font-family: "Vollkorn", serif;
        font-size: 30px;
        font-weight: 400;
        line-height: 45px;
        margin-top: 30px;
        opacity: 0.7;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .serpentine {
    margin: 100px 0 0;
  }

  .serpentine .serpentine-row {
    margin-bottom: 120px;
    &.last {
      margin-bottom: 30px;
    }
    & .text {
      padding: 0;
      & h3 {
        font-size: 40px;
        line-height: 44px;
      }
      & p {
        font-size: 22px;
        line-height: 33px;
      }
    }
    & .img-div img {
      height: 260px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .serpentine {
    margin: 90px 0 0;
  }

  .serpentine .serpentine-row {
    margin-bottom: 90px;
    &.last {
      margin-bottom: 0px;
    }

    & .img-div img {
      height: 220px;
    }
  }

  .serpentine .serpentine-row .text {
    padding: 0;
    & h3 {
      font-size: 30px;
      line-height: 33px;
      margin-top: 50px;
    }
    & p {
      font-size: 18px;
      line-height: 27px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 720px) {
  .serpentine {
    margin: 90px 0 0;
  }

  .serpentine .serpentine-row {
    margin-bottom: 90px;
    &.last {
      margin-bottom: 0px;
    }

    & .img-div img {
      height: 220px;
    }
  }

  .serpentine .serpentine-row .text {
    padding: 0;
    & h3 {
      font-size: 30px;
      line-height: 33px;
      margin-top: 50px;
    }
    & p {
      font-size: 18px;
      line-height: 27px;
      margin-top: 20px;
    }
  }
}

@media only screen and (max-width: 630px) {
  .formula .header {
    & br {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .serpentine {
    margin: 70px 0 0;
  }

  .serpentine .serpentine-row {
    margin-bottom: 70px;
    &.last {
      margin-bottom: 0px;
    }

    & .img-div img {
      height: 160px;
    }

    & .text {
      padding: 0;
      & h3 {
        font-size: 24px;
        line-height: 28px;
        margin-top: 20px;
      }
      & p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .serpentine {
    margin-top: 54px;
    & .serpentine-row {
      flex-direction: column;
      align-items: center;
      text-align: center;

      & .img-div {
        width: 100%;
        
        &.right {
          display: none;
        }
        &.mobile {
          display: block;
        }
        & img {
          height: 220px;
        }
      }
      
    & .text {
      padding: 0;
      width: 90%;
      & h3 {
        font-size: 24px;
        line-height: 28px;
        margin-top: 34px;
      }
      & p {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
      }
    }
    }
  }
}
